import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import InstShowcaseWrapper, {
    InstashowcaseCol,
    InstashowcaseRow,
    ShowcaseTitle
} from './style'
import InstagramGrid from '../../components/instagram-grid/instagram-grid'
import { IoLogoInstagram } from 'react-icons/io'

interface ShowcaseProps {}

const InstaDrawerGrid: React.FunctionComponent<ShowcaseProps> = (props) => {
    return <InstashowcaseRow></InstashowcaseRow>
}

export default InstaDrawerGrid
